<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Kompentensi : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- kompetensi verified -->
          <b-form-group label="Jenis Kompetensi" label-for="jenis_kompetensi">
            <validation-provider #default="{ errors }" name="jenis_kompetensi">
              <v-select
                id="jenis_kompetensi"
                v-model="filter.kompetensi_jenis"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="kompetensiList"
                placeholder="Pilih jenis kompetensi..."
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- kompetensi_no -->
          <b-form-group label="Nama Dokter" label-for="nama_dokter">
            <validation-provider #default="{ errors }" name="nama_dokter">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="nama_dokter"
                  placeholder="Search kompetensi no"
                  v-model="filter.orang_nama_lengkap"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status verified -->
          <b-form-group label="Status Kompetensi" label-for="status_kompetensi">
            <validation-provider #default="{ errors }" name="status_kompetensi">
              <v-select
                id="status_kompetensi"
                v-model="filter.kompetensi_verified"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusKompetensiList"
                placeholder="Pilih status kompetensi..."
              />
            </validation-provider>
            <b>true:terverifikasi, false:tidak terverifikasi</b>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- kompetensi_no -->
          <b-form-group label="Nomor Kompetensi" label-for="kompetensi_nomor">
            <validation-provider #default="{ errors }" name="kompetensi_nomor">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="kompetensi_nomor"
                  placeholder="Search kompetensi no"
                  v-model="filter.kompetensi_no"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- kompetensi_no -->
          <b-form-group label="Tanggal Dibuat" label-for="tanggal_dibuat">
            <validation-provider #default="{ errors }" name="tanggal_dibuat">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal_dibuat"
                  placeholder="Search kompetensi no"
                  v-model="filter.created_at"
                  type="date"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalUpdateKompentensi(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <b-modal
      id="modal-update-kompentensi"
      title="Edit Kompentensi"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <table style="width: 100%">
          <tr>
            <td>File</td>
            <td>:</td>
            <td v-if="updateKompentensi.kompetensi_file != null">
              <a
                v-if="
                  !updateKompentensi.kompetensi_file.includes('https') &&
                    !updateKompentensi.kompetensi_file.includes('base64')
                "
                :href="
                  'http://www.idijakpus.or.id/uploads/kompetensi/kompetensi_file/' +
                    updateKompentensi.id +
                    '/' +
                    updateKompentensi.kompetensi_file
                "
                alt="pdf"
                >Tampilkan File</a
              >
              <a v-else :href="updateKompentensi.kompetensi_file"
                >Tampilkan File</a
              >
              <!-- <img
                :src="'https://www.staging.idijakpus.or.id/uploads/gallery/gallery_file/' + updateKompentensi.kompetensi_file"
                alt="" width="200" height="200" /> -->
            </td>
            <td v-else>{{ "data tidak di temukan" }}</td>
          </tr>
        </table>

        <br />
        <b-form-input
          id="id_galeri"
          v-model="updateKompentensi.id"
          placeholder="Judul Galeri"
          hidden
        />
        <b-form-group
          label="Nomor Sertifikat Kompetensi"
          label-for="nomor_sertifikat_kompentensi "
        >
          <b-form-input
            id="nomor_sertifikat_kompentensi"
            v-model="updateKompentensi.kompetensi_no"
            placeholder="nomor_sertifikat_kompentensi"
          />
        </b-form-group>
        <b-form-group label="Jenis Kompetensi	" label-for="jenis_kompentensi	 ">
          <b-form-input
            id="jenis_kompentensi	"
            v-model="updateKompentensi.kompetensi_jenis"
            placeholder="jenis_kompentensi	"
          />
        </b-form-group>
        <b-form-group label="terferifikasi" label-for="vue-select">
          <v-select
            id="terferifikasi"
            v-model="updateKompentensi.kompetensi_verified"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionSkp"
            placeholder="terferifikasi.."
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="updateDataKompentensi"
        >
          Update Kompentensi
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "kompetensi_verified", label: "Status Verfikasi" }],
        [{ key: "orang_id.orang_nama_lengkap", label: "Nama Dokter" }],
        "kompetensi_no",
        "kompetensi_jenis",
        [{ key: "created_at", label: "Tanggal Dibuat" }],
        [{ key: "updated_at", label: "Tanggal Diupdate" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      detailPendidikan: {},
      detailGaleri: {},
      updateKompentensi: {},
      optionSkp: ["false", "true"],
      filter: {
        kompetensi_jenis: null,
        kompetensi_no: null,
        kompetensi_verified: null,
        orang_nama_lengkap: null,
        created_at: null,
      },
      kompetensiList: ["Umum", "Spesialis"],
      statusKompetensiList: ["true", "false"],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getKompentensi();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
    reloadPage() {
      location.reload();
    },

    modalUpdateKompentensi(item) {
      this.updateKompentensi = item;
      this.$bvModal.show("modal-update-kompentensi");
    },

    async getKompentensi() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.kompentensi.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data kompentensi");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async updateDataKompentensi() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var ids = this.updateKompentensi.id;

        let body = {
          kompetensi_no: this.updateKompentensi.kompetensi_no,
          kompetensi_jenis: this.updateKompentensi.kompetensi_jenis,
          kompetensi_verified: this.updateKompentensi.kompetensi_verified,
        };
        console.log(ids, body);
        await API.kompentensi.updateData(ids, body);
        this.refreshPage();
        this.handleMsgSuccess("Berhasil Update kompentensi");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal update kompentensi");
      } finally {
        this.$bvModal.hide("modal-update-kompentensi");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getKompentensi();
  },
  watch: {
    currentPage() {
      this.getKompentensi();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
